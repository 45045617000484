<template>
	<v-toolbar flat>
		<v-toolbar-title>My Profile</v-toolbar-title>
	</v-toolbar>
</template>

<script>
	import { mapState } from "vuex";
	export default {
		name: "MyQuotes",
		computed: {
			...mapState([
				"account",
			]) /* 'account' is referring to the module account.js */,
		},
	};
</script>

